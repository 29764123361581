.cursor {
    cursor: pointer;
}

#footer :hover {
    color: blueviolet;
    text-decoration: underline;
    cursor: pointer;
}

.blink {
    animation: blinker .8s linear infinite;
}

.zoomPulse {
    animation: zoom-in-zoom-out 1.5s ease infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(.8, .8);
    }

    50% {
        transform: scale(1.05, 1.05);
    }

    100% {
        transform: scale(.8, .8);
    }
}